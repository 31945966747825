import React from 'react'
import { Link } from 'gatsby'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'

import mq from 'styles/breakpoints'
import socialIcons from 'constants/social-icons'

const Footer = () => {
  const { t } = useTranslation('footer')

  const FooterContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #000000;
    color: white;
    width: 100%;
    font-size: 1.5rem;
    font-family: 'arial';
    padding-left: 15%;
    padding-right: 15%;
    padding-top: 4rem;
    padding-bottom: 4rem;

    ${mq['md']} {
      font-size: 1rem;
      padding-bottom: 4rem;
      padding-top: 4rem;
      border: none;
    }

    ${mq['md']} {
      align-items: center;
    }

    ${mq['xsm']} {
      margin-top: 5rem;
      padding: 0;
      padding-top: 6rem;
      height: 17rem;
    }
  `

  const SocialMediaIconsContainer = styled.div`
    font-size: 2.7rem;
    margin-bottom: 3rem;

    a {
      color: white;
      text-decoration: none;
      cursor: pointer;
      margin-right: 2.5rem;

      &:hover {
        color: #0080fa;
        transition: 0.2s;
      }
    }

    ${mq['md']} {
      font-size: 1.4rem;
    }
  `

  const CopyrightTextContainer = styled.div`
    align-items: center;
    justify-content: center;
    display: flex;
  `

  const Legal = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;

    a {
      color: white;
      text-decoration: none;
      margin: 0em 1em;
      font-size: 1.5rem;

      ${mq['md']} {
        font-size: 1rem;
      }
    }

    ${mq['md']} {
      margin-top: 1.5em;
    }
  `

  const FlexContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;

    ${mq['md']} {
      flex-direction: column;
    }
  `

  return (
    <FooterContainer>
      <SocialMediaIconsContainer>
        {socialIcons.map((item, index) => {
          return (
            <a
              key={index}
              href={item.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {item.icon}
            </a>
          )
        })}
      </SocialMediaIconsContainer>
      <FlexContainer>
        <CopyrightTextContainer>
          {' '}
          © {new Date().getFullYear()} FluentWorlds | {t('allRightsReserved')}
        </CopyrightTextContainer>
        <Legal>
          <Link to="/terms-of-use">{t('termsOfUse')}</Link>
          <Link to="/privacy-policy">{t('privacyPolicy')}</Link>
        </Legal>
      </FlexContainer>
    </FooterContainer>
  )
}

export default Footer
