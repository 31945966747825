import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import UserContext from 'context/user/UserContext'

import { useAuth0 } from 'utils/react-auth0-spa'
import {
  UserAndButtonContainer,
  User,
  SignOutButtonWrapper,
  SignUpButtonWrapper,
} from 'components/HeaderComponents'

const UserAuth = ({ signInPath, logoutReturnPath, digitalGoQueryParam }) => {
  const { username } = useContext(UserContext)
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0()
  const { t, i18n } = useTranslation('header')

  const onClickSignUp = () => {
    loginWithRedirect({
      ui_locales: i18n.language.substring(0, 2),
      redirect_uri:
        process.env.GATSBY_WEBSITE_URL +
        signInPath +
        (digitalGoQueryParam || ''),
    })
  }

  return (
    <>
      {isAuthenticated && username !== '' ? (
        <UserAndButtonContainer>
          <User>
            {t('greeting')} {username}
          </User>
          <SignOutButtonWrapper onClick={() => logout(logoutReturnPath)}>
            {t('signOut')}
          </SignOutButtonWrapper>
        </UserAndButtonContainer>
      ) : (
        <UserAndButtonContainer>
          <SignUpButtonWrapper onClick={() => onClickSignUp()}>
            {t('signUp')}
          </SignUpButtonWrapper>
        </UserAndButtonContainer>
      )}
    </>
  )
}

UserAuth.defaultProps = {
  signInPath: '/account/',
  logoutReturnPath: '',
}

UserAuth.propTypes = {
  signInPath: PropTypes.string,
  logoutReturnPath: PropTypes.string,
}

export default UserAuth
