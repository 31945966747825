import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useAuth0 } from 'utils/react-auth0-spa'

const UserAuthNavLinks = ({ signInPath, accountPath, digitalGoQueryParam }) => {
  const { isAuthenticated, loginWithRedirect } = useAuth0()
  const { t, i18n } = useTranslation('header')

  const onClickSignIn = () => {
    loginWithRedirect({
      ui_locales: i18n.language.substring(0, 2),
      redirect_uri:
        process.env.GATSBY_WEBSITE_URL +
        signInPath +
        (digitalGoQueryParam || ''),
    })
  }

  return (
    <>
      {isAuthenticated ? (
        <li>
          <Link
            to={accountPath}
            activeStyle={{ color: '#0080fa', fontWeight: 'bold' }}
          >
            {t('myAccount')}
          </Link>
        </li>
      ) : (
        <li>
          <a href="#signin" onClick={() => onClickSignIn()}>
            {t('signIn')}
          </a>
        </li>
      )}
    </>
  )
}

UserAuthNavLinks.defaultProps = {
  signInPath: '/account/',
  accountPath: '/account/',
}

UserAuthNavLinks.propTypes = {
  signInPath: PropTypes.string,
  accountPath: PropTypes.string,
}

export default UserAuthNavLinks
