import React from 'react'
import styled from '@emotion/styled'
import { FaBars } from 'react-icons/fa'
import Loader from 'react-loader-spinner'
import mq from 'styles/breakpoints'

export const NavbarContainer = styled.nav`
  margin: 0 0 0 0;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  position: relative;

  ${mq['sm']} {
    margin-top: 0em;
  }

  ${mq['lg']} {
    margin: 0 0 0 0;
    height: 75px;
  }
`

export const NavCenter = styled.div`
  @media screen and (min-width: 992px) {
    background-color: transparent;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

export const NavHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 1.25rem;
  height: 10rem;

  img {
    height: 10rem;
  }

  ${mq['xl']} {
    img {
      display: none;
    }
  }

  ${mq['lg']} {
    height: 2em;
    img {
      display: none;
    }
    overflow: hidden;
    margin-bottom: 12rem;
  }

  ${mq['sm']} {
    img {
      display: none;
    }
    overflow: hidden;
  }
`

export const NavLinksWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const NavHeaderDigitalGo = styled(NavHeader)`
  ${mq['lg']} {
    height: 2em;
    margin-bottom: 3rem;
  }

  ${mq['sm']} {
    margin-bottom: 6rem;
  }
`

export const LogoButton = styled.button`
  margin-top: 4.5em;
  margin-left: 0.5em;
  margin-right: auto;
  background: transparent;
  border: none;
  outline: none;
  &:hover {
    cursor: pointer;
  }
`

export const BurgerIcon = styled(FaBars)`
  margin-left: auto;
  margin-right: auto;
  font-size: 3rem;
  &:hover {
    cursor: pointer;
  }

  @media screen and (min-width: 992px) {
    display: none;
  }

  ${mq['lg']} {
    margin-bottom: 2em;
    position: absolute;
    top: 22px;
    left: 16px;
  }
`

export const SelectContainer = styled.div`
  width: 15rem;
  font-size: 1.5rem;

  ${mq['xl']} {
    margin-left: 0.6em;
  }
`

export const UserAndButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  width: 18rem;
  padding: 1rem 0;

  ${mq['lg']} {
    position: absolute;
    top: 2rem;
    right: 1.5rem;
    align-items: flex-end;
  }

  ${mq['sm']} {
    top: 1.5rem;
  }
`

export const User = styled.span`
  font-size: 1.5rem;

  ${mq['lg']} {
    display: none;
  }
`

export const SignUpButtonWrapper = styled.button`
  outline: none;
  background-color: #28a745;
  color: white;
  border-radius: 0.5rem;
  border: none;
  font-size: 1.8rem;
  padding: 0.8rem 1.6rem;
  text-decoration: none;
  cursor: pointer;
`

export const SignOutButtonWrapper = styled.button`
  outline: none;
  background-color: #dc3545;
  color: white;
  border-radius: 0.5rem;
  border: none;
  font-size: 1.8rem;
  padding: 0.8rem 1.6rem;
  text-decoration: none;
  cursor: pointer;
`

export const LoaderWrapperSignUp = styled.button`
  position: relative;
  border: none;
  outline: none;
  background: transparent;
  right: 16px;
  ${mq['lg']} {
    position: absolute;
    top: 1em;
    right: 16px;
  }
`

export const LoaderWrapperSignIn = styled.button`
  position: relative;
  border: none;
  outline: none;
  background: transparent;
  right: 16px;
  ${mq['lg']} {
    position: absolute;
    top: 1em;
    right: 16px;
  }
`

export const LoadingIndicatorSignUp = (
  <LoaderWrapperSignUp>
    <Loader type="ThreeDots" color="#4A90E2" height={50} width={50} />
  </LoaderWrapperSignUp>
)

export const LoadingIndicatorSignIn = (
  <LoaderWrapperSignIn>
    <Loader type="ThreeDots" color="#4A90E2" height={50} width={50} />
  </LoaderWrapperSignIn>
)
